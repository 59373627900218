.background {
  background-color: #006666;
}
.language-selector {
  display: flex !important;
  justify-content: flex-end !important;
}
.intro {
  height: 900px;
}
.companyName {
  font-size: 80px;
}
.company {
  text-align: start;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  height: 500px;
  color: aliceblue;
  font-size: 24px;
}
.card {
  background-color: #339966;
  color: aliceblue;
}
.about-us {
  height: 400px;
  align-items: center;
  display: flex;
  color: #006666;
  font-size: 18px;
  text-align: start;
}
.links {
  font-size: 19px;
}
.our-mission {
  height: 350px;
  text-align: start;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  color: aliceblue;
  font-size: 18px;
}
.our-vision {
  height: 420px;
  text-align: start;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  color: #006666;
  font-size: 18px;
}
/* Footer.css */

.mobile-footer {
  display: flex;
  justify-content: flex-start;
}
.social-icon {
  font-size: 24px;
  color: aliceblue;
  margin: 0 10px;
  text-decoration: none;
  margin-top: 10px;
}

.social-icon:hover {
  color: #007bff; /* Change color on hover if desired */
}
.bg {
  background-color: #006666;
  color: aliceblue;
}

@media (max-width: 767px) {
  .nav-brand-mobile {
    margin-left: -28px;
  }
  .intro {
    height: 1100px;
  }
  .companyName {
    font-size: 60px;
  }
  .company {
    text-align: center;
  }
  .mobile-card {
    margin: 0 !important;
    margin-bottom: 10px !important ;
  }
  .about-us {
    height: 550px;
  }
  .our-mission {
    height: 550px;
  }
  .our-vision {
    height: 720px;
  }
  .mobile-footer {
    text-align: center !important;
    justify-content: center !important ;
    margin-bottom: 10px;
  }
}
